import * as React from "react"

// so aws amplify runs
const Index = () => {
  return (
    <main></main>
  )
}

export default Index
